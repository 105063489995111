import NavBar from "./components/navbar.js"
import Footer from "./components/footer.js"
import Button from 'react-bootstrap/Button'
import About from "./pages/about.js";
import Home from "./pages/home.js";
import Projects from "./pages/projects.js";
import CV from "./pages/cv.js";
// import Contact from "./pages/contact.js";
import Tuntre from "./pages/tuntre.js";
import ConnectExplore from "./pages/connect-explore.js";
import RemoteSignalSystem from "./pages/remote-signal-system.js";
import Vibe from "./pages/vibe.js";
import ClayGroot from "./pages/clay-groot.js";
import Kagu from "./pages/kagu.js";
import AirHockeyRobot from "./pages/air-hockey-robot.js";
import GreenSquare from "./pages/green-square.js";
import Portal from "./pages/portal.js";
// import { BrowserRouter as Routes, Switch, Route } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="container">
        <div className="appcontentcontainer">
          <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/cv" element={<CV />} />
                {/* <Route path="/contact" element={<Contact />} /> */}
                <Route path="/tuntre" element={<Tuntre />} />
                <Route path="/connect-explore" element={<ConnectExplore />} />
                <Route path="/remote-signal-system" element={<RemoteSignalSystem />} />
                <Route path="/vibe" element={<Vibe />} />
                <Route path="/clay-groot" element={<ClayGroot />} />
                <Route path="/kagu" element={<Kagu />} />
                <Route path="/air-hockey-robot" element={<AirHockeyRobot />} />
                <Route path="/green-square" element={<GreenSquare />} />
                <Route path="/portal" element={<Portal />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;