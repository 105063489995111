import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Photos/Projects/Groot/groot.jpg";
import SectionTitle from '../components/sectiontitle';
import {Container, Row, Col} from 'react-bootstrap';
import img1 from "../assets/Photos/Projects/Groot/groot_wip1.jpeg";
import img2 from "../assets/Photos/Projects/Groot/groot_wip2.jpeg";

function ClayGroot() {
  return (
    <ProjectPage 
    title = "Clay Baby Groot" 
    tags = "Clay, Sculpting, Art"
    content = {
        <>
          <p>Just a fun project I want to show off!</p>
          <Container>
            <Row>
              <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img src={heroImg} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
              </Col>
              <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img src={img1} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
              </Col>
              <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img src={img2} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
              </Col>
            </Row>
          </Container>
        </>
    }
    />
  )
}

export default ClayGroot