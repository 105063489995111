import React from 'react'
import {useNavigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import './independentnav.css'

function IndependentNav({href}) {
    const navigate = useNavigate();

    const redirect = () =>{
        navigate(href);
    }
  return (
    <Button onClick={redirect} className="indnavbtn">All Projects</Button>
  )
}

export default IndependentNav