import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Photos/Projects/Ocean Infinity/untitled5.png";
import SectionTitle from '../components/sectiontitle';
import { Link } from 'react-router-dom';
import img1 from "../assets/Photos/Projects/Ocean Infinity/PXL_20210907_153208381.jpg"

function RemoteSignalSystem() {
  return (
    <ProjectPage 
    title = "Remotely Operable Signalling System for Autonomous Ships" 
    tags = "Mechatronics, Arduino, Programming, System Design"
    hero = {heroImg}
    content = {
        <>
            <SectionTitle title="Project Overview" />
            <p class="disclaimer">
              *This was a project worked on during my time at Ocean Infinity (see "Experience" on the home page) in collaboration with Andy Ferdinand, Brandon Tan, Harvey Wong, and Shefali Bagaria. <br/>
              <b>*As a patent for the design of the system is in the works, and I am under an NDA, details of the system will not be disclosed.</b>
            </p>
            <p>
              The project was to design a system to display signal flags on the Armada vessels remotely. 
              The Armada (at the time of the placement) is to be a fleet of 15 robotic uncrewed surface vessels that are 21m, 36m, and 78m in length. 
              These vessels will be used for inspection, surveying, searching, and shuttling jobs.
              As international maritime regulations require all vessels to carry a full set of signalling flags, this project is critical to the operation of the Armada vessels. 
            </p>
            <p>
              This project was conducted in phases.
            </p>
            <h4><b>Design Phase</b></h4>
            <p>
              During the design phase, I applied design engineering methods like morphological analysis and sketching to come up with and communicate ideas with team members and colleagues. 
              MBSE (Model-Based System Engineering) tools were also used to assist in this process. These tools included the holistic requirements model, the n^2 matrix, the Pugh matrix, and functional means analysis. 
              For this project I also used basic materials I had around me to assist with communicating ideas with team members and colleagues. 
              Simple things like sticky notes, cardboard and tape proved to be quite useful in quickly making a physical representation of how a mechanism I imagined in my head would work. 
              This mechanism and concept was ultimately the one that was chosen and developed.
            </p>
            <h4><b>Prototyping Phase</b></h4>
            <img src={img1} style={{width: "500px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", display: "block", marginLeft: "auto", marginRight: "auto"}} />
            <p>
              <br/>
              For the prototyping phase, I was in charge of and worked with one other placement student on building the control system and having it interface with the control system for the Armada vessels. 
              For this, I helped to develop a communication protocol between different Arduino micro-controllers that allowed multiple components within our system to communicate with each other.
              I also worked closely with the company helping develop the Armada’s control system on the communication protocol between our system and the Armada’s control system. 
              My main work however was on the logic that lives on each micro-controller to determine what to do with the information sent and received between them. 
              This code now lives on the physical working prototype that remains with the company, and a patent for the design of the system is also in the works.
            </p>
        </>
    }
    />
  )
}

export default RemoteSignalSystem