import React from 'react'

function SectionTitle({title}) {
  return (
    <>
        <div style={{fontWeight: 1000, fontSize: "30px", marginTop:"20px"}}>{title}</div>
        <hr style={{marginTop:"0px"}}/>
    </>
  )
}

export default SectionTitle