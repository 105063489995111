import React from 'react'
import './footer.css'
import logo from '../assets/logo.png' // relative path to image 
import linkedinlogo from '../assets/Logos/LinkedIn-Logos/LI-Logo.png'

function Footer() {
  return (
    <footer>
        <div>
            <a href="/"><img src={logo} className="icon logo"></img></a>
            <p></p>
            <a href="/about"><h6><b>About</b></h6></a>
            <a href="/projects"><h6><b>Projects</b></h6></a>
            <a href="/cv"><h6><b>CV</b></h6></a>
        </div>
        <div className="contact">
            <h6><b>Contact</b></h6>
            <p>
                <b>Email: </b> jonjjt2000@gmail.com
                <br></br>
                <b>Phone: </b> +44 (0)7365916933
            </p>
            <a href="https://www.linkedin.com/in/jonathan-james-tang-833837159/" target="blank"><img src={linkedinlogo} className="icon"></img></a>
        </div>
    </footer>
  )
}

export default Footer