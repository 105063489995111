import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_01.png";
import SectionTitle from '../components/sectiontitle';
import ImageCarousel from '../components/imgcarousel';
import img1 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_02.png";
import img2 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_03.png";
import img3 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_04.png";
import img4 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_05.png";
import img5 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_06.png";
import img6 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_07.png";
import img7 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_08.png";
import img8 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_09.png";
import img9 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_10.png";
import img10 from "../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_11.png";

function Vibe() {
  return (
    <ProjectPage 
    title = "Vibe" 
    tags= "Industrial Design, CAD, Blender"
    content = {
        <>
            <p>
              This project was done during the COVID-19 lockdown as an industrial design exercise. 
              As such, access to physical prototyping tools was limited and the majority of work done was with CAD tools.
            </p>
            <ImageCarousel imageArray={[<img src={heroImg}/>, <img src={img1}/>, <img src={img2}/>, <img src={img3}/>, <img src={img4}/>, <img src={img5}/>, <img src={img6}/>, <img src={img7}/>, <img src={img8}/>, <img src={img9}/>, <img src={img10}/>]} variant="dark"/>
        </>
    }
    />
  )
}

export default Vibe