import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Animations/0001_2.gif";
import SectionTitle from '../components/sectiontitle';
import sitePDF from '../assets/tuntredotcodotuk.pdf';
// import sitePDF from '../assets/JonathanTang_CV.pdf';

function Tuntre() {
  return (
    <ProjectPage 
    title = "Tuntre" 
    tags = "Startup, Industrial Design, Product Design, Marketing, Media, Animation"
    hero = {heroImg} 
    video = "https://youtube.com/embed/FRcCZZPg0_E"
    content = {
        <>
            <SectionTitle title="Project Overview" />
            <p class="disclaimer">
              *This was a group project done with Ke Ding, Emilio Severi, and Ethan Chow. <br />
            </p>
            <p>
              Tuntre is a concept plant care start-up and product that helps "plant parents" advance their skills and care for their plants without taking away the joy of hands-on "plant parenthood". 
              <br /><br />
              We built a brand with a clear mission and values, validated it with relevant stakeholders, and designed and prototyped a concept product that aimed to carry out this mission.
              While our project website is no longer up, more information can be found at the embedded PDF of the project website below.
              <br /><br />
              I was heavily involved with stakeholder engagement, conducting interviews with plant care influencers, enthusiasts, and garden centres, 
              and I was in charge of creating content for and running the company social media page and ad campaigns on instagram <a href='https://www.instagram.com/tuntreplants/?hl=en' target="_blank">@tuntreplants</a>.
            </p>
            <SectionTitle title="Project Website" />
            <iframe src={sitePDF} style={{width: "100%", height: "80vh", borderRadius: "15px", boxShadow: "3px 3px 3px #f5f5f5"}}></iframe>
        </>
    }
    />
  )
}

export default Tuntre;