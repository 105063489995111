import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/logo.png' // relative path to image 
import "./navbar.css"
import SideBar from './sidebar';

function NavBar() {
  return (
    <>
      <Navbar className="mb-3">
        <Container >
          <Navbar.Brand href="/">
          <img
            src={logo}
            width="35"
            height="30"
            className="d-inline-block align-top"
            alt="Jonathan Tang logo"
          />
          </Navbar.Brand>
          <SideBar />
          <div className="d-none d-md-block">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <NavDropdown title="Projects">
                <NavDropdown.Item href="/projects">All Projects</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/tuntre">Tuntre</NavDropdown.Item>
                <NavDropdown.Item href="/connect-explore">Connect: Explore</NavDropdown.Item>
                <NavDropdown.Item href="/remote-signal-system">Remote Signalling System</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/cv">CV</Nav.Link>
              {/* <Nav.Link href="/contact">Contact</Nav.Link> */}
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;