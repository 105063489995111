import React from 'react'
import Intro from "../components/intro.js";
import Profile from "../assets/Photos/Me/DSC4515.png";
import SectionTitle from '../components/sectiontitle.js';
import {Container, Row, Col, Accordion, Button} from 'react-bootstrap';
import linkedinlogo from '../assets/Logos/LinkedIn-Logos/LI-Logo.png'
import image1 from "../assets/Photos/Me/IMG_0656.JPG";
import image2 from "../assets/Photos/Me/197580_1010724847174_4043021_n.jpg";
import image3 from "../assets/Photos/lightbarbracket1.webp";
import image4 from "../assets/Photos/lightbarbracket2.webp";
import image5 from "../assets/Photos/lightbarbracket3.webp";
import image6 from "../assets/Photos/Me/DSC01656-2.jpg";
import image7 from "../assets/Photos/Me/WhatsApp Image 2018-04-18 at 5.44.17 PM.jpeg";
import image8 from "../assets/Photos/Me/VIDEO-2021-05-07-23-58-03_Moment.jpg";
import image9 from "../assets/Photos/Me/20220412_141339.jpg";

function About() {
  return (
    <>
      <SectionTitle title={'About me'} />
      <Intro 
        img_src={Profile} 
        text={
            <p>
                Hi, I'm Jon! I've just graduated from the <b>MEng Design Engineering</b> course at Imperial College London's <b>Dyson School of Design Engineering.</b> 
                <br/><br/>
                I grew up in Hong Kong, am a London based British Citizen, and currently looking for full time work. 
                <br/><br/>
                You can contact me at: <br/>
                <b>Email: </b>jonjjt2000@gmail.com<br/>
                <b>Phone: </b>+44 (0)7365916933<br/>
                <a href="https://www.linkedin.com/in/jonathan-james-tang-833837159/" target="blank"><img src={linkedinlogo} style={{height: "30px"}}></img></a>
            </p>
        }
      />
      <SectionTitle title={'I like making stuff'} />
      <Container>
        <Row>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image1} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image2} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
        </Row>
      </Container>
      <p>
        <br/>
        Growing up, I was always building and tinkering with things. 
        In fact, I distinctly remember in the second grade one time I accidentally hit a girl in the neck with a rubber that I'd fired out of a slingshot I'd made with a couple pencils and a rubberband.
        <br/><br/>
        A couple years later, I'd moved on to turning the popular Zebra Sarasa Clip pens into "guns", 
        leveraging friction and the included spring to fire the lower cam (or thrust device) out of the barrel.
      </p>
      <Container>
        <Row>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image3} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image4} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image5} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
        </Row>
      </Container>
      <p>
        <br/>
        These days, sometimes I'll 3D print something I need - for example, I modelled and printed some brackets to mount a light bar to the bottom of my loft bed above my desk.
        But if you want to look at more things I've done, check out my <a href="/projects" style={{fontWeight: "bold", textDecoration: "none", color: "initial"}}>projects</a> page!
      </p>
      <SectionTitle title={'I like doing other things too'} />
      <Container>
        <Row>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image6} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image7} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image8} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
          <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img src={image9} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default About