import React from 'react'
import SectionTitle from './sectiontitle'
import "./project-page.css"

function ProjectPage({title, tags, hero, video, content}) {
  const Iframe = ({videosrc})  => {
    console.log(videosrc);
    if (videosrc === undefined){
      return;
    } else {
      return <div className="projectVideo"><SectionTitle title="Project Video" /><div className="iframeContainer"><iframe src={videosrc} allowFullScreen="true" autoplay="0"/></div></div>;
    }
  };

  const Tags = ({tagsin}) => {
    if (tagsin !== undefined) {
      return <p><i>Tags: {tagsin}</i></p>;
    }
  }
  return (
    <>
    <SectionTitle title={title} />
    <Tags tagsin={tags} />
    <div className="heroImg"><img src={hero} /></div>
    <Iframe videosrc={video}/>
    <div className="projectContent">{content}</div>
    </>
  )
}

export default ProjectPage