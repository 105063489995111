import React from 'react';
import Intro from "../components/intro.js";
import Profile from "../assets/Photos/Me/DSC4515.png";
import Slider from '../components/slider.js';
import ExpandableCard from '../components/expandablecard.js';
import SectionTitle from '../components/sectiontitle.js';
import {Container, Row, Col, Accordion, Button} from 'react-bootstrap';
import IndependentNav from '../components/independentnav.js';
import ProjectBlock from '../components/projectblock.js';
import icon1 from '../assets/Photos/Projects/Tuntre/Tuntre White Name.png';
import projectimage1 from '../assets/Photos/Projects/Tuntre/hero2_denoised.png';
import icon2 from '../assets/Photos/Projects/Connect Explore/Logo white - Copy.png';
import projectimage2 from '../assets/Photos/Projects/Connect Explore/FINALFINALPRESENTATION_Page_37 - Copy.png';
import icon3 from '../assets/Logos/OI/RGB/OI_Master_Icon_White_RGB.png';
import projectimage3 from '../assets/Photos/Projects/Ocean Infinity/untitled5.png';
import experienceimage1 from '../assets/Logos/OI/RGB/OI_Master_Logo_with_Grad_RGB.png';

function Home() {
  return (
    <>
        <Intro 
            img_src={Profile} 
            text={
                <p>
                    Hi, I'm Jon! I've just graduated from the <b>MEng Design Engineering</b> course at Imperial College London's <b>Dyson School of Design Engineering.</b>
                    <br></br>
                    You can check out some of my work below!
                </p>
            }
        />
        <Slider />
        <SectionTitle title={"Featured Projects"} />
        <Container>
            <IndependentNav href={"/projects"} />
            <Row>
                <Col>
                <ProjectBlock icon_src={icon1} img_src={projectimage1} href={"/tuntre"} />
                </Col>
                <Col>
                <ProjectBlock icon_src={icon2} img_src={projectimage2} href={"/connect-explore"}/>
                </Col>
                <Col>
                <ProjectBlock project_title={"Remote Signalling System"} img_src={projectimage3} href={"/remote-signal-system"}/>
                </Col>
            </Row>
        </Container>
        {/* <SectionTitle title={"Current"} /> */}
        <SectionTitle title={"Experience"} />
        <ExpandableCard 
            id="oceaninfinity"
            heroimg={experienceimage1}
            bodytext={
                <>
                    <h4><b>Design Engineering Intern (April - September 2021)</b></h4>
                    {/* <p>
                        Six month design engineering placement at a marine robotics company.<br />
                        - Learned and practiced model based systems engineering<br />
                        - Developed a novel, remotely operated flag signalling system for remotely operated marine vessels
                    </p> */}
                    <p>
                        <a href="https://oceaninfinity.com/" target="_blank" style={{fontWeight: "bold", textDecoration: "none", color: "initial"}}>Ocean Infinity</a> is a marine robotics company with teams based in the U.S., Europe, and Asia. I was based in Southampton, UK during my time with the company.<br />
                    </p>
                    <p>
                        During my time at Ocean Infinity, I worked on a few different projects that form parts of Ocean Infinity’s Armada project. 
                        Armada (at the time of the placement) is to be a fleet of 15 robotic uncrewed surface vessels that are 21m, 36m, and 78m in length.
                        These vessels will be used for inspection, surveying, searching, and shuttling jobs.
                    </p>
                    <h5><a href={"/remote-signal-system"} style={{fontWeight: "bold", textDecoration: "none", color: "initial"}}>Remote Signalling System</a></h5>
                    <Intro 
                        img_src={projectimage3} 
                        text={
                            <>
                                <p>
                                    The main project I worked on was to design a system to display signal flags on the Armada vessels remotely. 
                                    As international maritime regulations require all vessels to carry a full set of signalling flags, 
                                    this project is critical to the operation of the Armada vessels. 
                                    <br/><br/>
                                    This was a design and mechatronics project that involved research, stakeholder engagement, and prototyping in the context of a larger project (Armada).<br />
                                    Find out more at: <a href={"/remote-signal-system"} style={{fontWeight: "bold", textDecoration: "none", color: "initial"}}>Remote Signalling System</a>.
                                </p>
                                
                            </>
                        }
                    />         
                    <h5><b>ROV Tooling</b></h5>
                    <p>
                        Another project I worked on was an ROV (Remotely Operated Vehicle) tooling project to equip an ROV with a large range of sensors to perform specific tasks, while fitting within a size constraint.
                        What made this a challenge was the specifications of different sensors that needed to be a certain distance away from other components or sensors also on the ROV to remove electrical and other interference.
                        My work on this project involved using MBSE (Model-Based Systems Engineering) tools and regular consultation with Payload Integration Technicians and their supervisors to constrain and articulate all the requirements of the system and its components. 
                    </p>
                    <p>
                        While working on this project, I completed and had approved a comprehensive requirements document that was later used as the backbone of development of a report that illustrated possible configurations of the system. 
                        This requirements document and report now are in the file system of the company for their internal use.
                    </p>
                    <h5><b>Communication Protocol and Hardware Implementation for Earth Fault Detection on Deployed ROVs</b></h5>
                    <p>
                        I was also given the opportunity to work under a systems engineer and a mechatronics engineer on a project to do with survey equipment payloads to be put on the ship. 
                        I was given the task of using a Controllino Maxi micro-controller to interpret signals from an earth fault detection and emergency stop panel and integrate it into the payload control system. 
                        For this, I wrote the logic for the micro-controller and helped develop the communication protocol between the micro-controller and the larger control system. 
                        This component now is fully implemented into the control system and works for its intended purpose.
                    </p>
                </>
            }
            >
        </ExpandableCard>
        {/* <ProjectBlock img_src={experienceimage1} href={"/ocean-infinity"} /> */}
    </>
  )
}

export default Home