import React from 'react'
import {Container} from 'react-bootstrap'
import "./projectblock.css"
import {useNavigate} from 'react-router-dom';

function ProjectBlock({img_src, project_title, icon_src, href}) {
    const navigate = useNavigate();

    const redirect = () =>{
        navigate(href);
    }
    return (
        <Container className="projectBlock" onClick={redirect}>
            <div className="projectBrand">
                <div className="projectIcon">
                    <img src={icon_src}></img>
                </div>
                <div className="projectTitle">
                    <p>{project_title}</p>
                </div>
            </div>
            <img src={img_src} className="projectImage"></img>
        </Container>
    )
}

export default ProjectBlock;