import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Animations/airhockey.gif";
import SectionTitle from '../components/sectiontitle';
import video from "../assets/Videos/Gameplay3.mp4";
import {Container, Row, Col, Accordion, Button} from 'react-bootstrap';
import img1 from "../assets/Photos/Projects/Air Hockey/Mark_-_Air_Hockey_Masters_Demo_AdobeExpress.gif";
import img2 from "../assets/Photos/Projects/Air Hockey/table-tennis-training.gif";
import img3 from "../assets/Photos/Projects/Air Hockey/Colin_Cummings_-_Diamond_Drift_AdobeExpress.gif";
import img4 from "../assets/Photos/Projects/Air Hockey/Colin_Cummings_-_Puck_Control_1_AdobeExpress.gif";
import ImageCarousel from '../components/imgcarousel';
import img5 from "../assets/Photos/Projects/Air Hockey/Viva Slides/Slide24.PNG";
import img6 from "../assets/Photos/Projects/Air Hockey/Viva Slides/Slide25.PNG";
import img7 from "../assets/Photos/Projects/Air Hockey/Viva Slides/Slide26.PNG";
import img8 from "../assets/Photos/Projects/Air Hockey/Viva Slides/Slide27.PNG";
import img9 from "../assets/Photos/Projects/Air Hockey/Viva Slides/Slide28.PNG";
import img10 from "../assets/Photos/Projects/Air Hockey/Viva Slides/Slide30.PNG";
import img11 from "../assets/Photos/Projects/Air Hockey/Viva Slides/Slide31.PNG";

function AirHockeyRobot() {
  return (
    <ProjectPage 
    title = "Generation of an Action Set for an Air Hockey Playing Robot" 
    tags = "Mechatronics, Programming, C++, Python"
    hero = {heroImg} 
    content = {
        <>
            <SectionTitle title="Gameplay Video" />
            <video controls style={{width: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)"}}>
                <source src={video} type="video/mp4" />
            </video>
            <SectionTitle title="Project Overview" />
            <p class="disclaimer">
              *This was a continuation of work done by Marc Tcheroyan, Eisa Anwar, Camelot Gilbert, Maxim Hunt, and Mark Ridge, in collaboration with Hsinhua Lu.
            </p>
            <p>
              The overarching project to develop an air hockey playing robot began in 2019 with Marc Tcheroyan, Eisa Anwar, and Camelot Gilbert. 
              Tcheroyan and Anwar built the hardware, and Gilbert developed a machine vision system for the robot.
              <br/><br/>
              By 2021, Maxim Hunt developed a control system, and Mark Ridge began work on a strategy system.
              {/* <br/><br/>
              By 2022, Hsinhua Lu developed a strategy system, and I generated an action set and an arbitrary layered strategy system as a test bed. */}
            </p>
            <h5><b>Previous Strategy Systems</b></h5>
            <Container>
                <Row>
                <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img src={img1} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
                </Col>
                <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img src={img2} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img>
                </Col>
                </Row>
            </Container>
            <p>
                <br/>
                Previous attempts at strategy design for air hockey playing robots treated air hockey like a "net/wall" sport, eg. table tennis. 
                Meaning that each player can only take one action before sending the puck/ball back to the other side.
                <br/><br/>
                <h6><b>Other Air Hockey Robots</b></h6>
                <ul>
                    <li><a href="https://www.researchgate.net/publication/261415702_Hierarchical_processing_architecture_for_an_air-hockey_robot_system" target="_blank">Hierarchical processing architecture for an air-hockey robot system</a></li>
                    <li><a href="https://www.semanticscholar.org/paper/Vision-based-control-of-an-air-hockey-playing-robot-Bishop-Spong/df93b4563a2ece90048a1417581fadbeab12fcdc" target="_blank">Vision-based control of an air hockey playing robot</a></li>
                    <li><a href="https://www.semanticscholar.org/paper/A-vision-based-air-hockey-system-with-fuzzy-control-Wang-Tsai/1d6e33fdbd3df8157d90b3d2ed8afe0405f8d526" target="_blank">A vision based air hockey system with fuzzy control</a></li>
                </ul>
                Strategy systems in previous air hockey playing robots work by deciding on an action the robot should take out of a set of actions.
                This set of actions is almost always some variation of: attack, block, stand by.
                <br/><br/>
                However, air hockey is a much more complex game, and any number of actions can be taken with the puck before sending it back to the other side (there is a 7 second time limit).
                Below, <a href="https://www.youtube.com/channel/UC9nvXmQN5u6BQw3ptoxVmag" target="_blank">Colin Cummings</a>, a multi-time air hockey world champion, demonstrates such actions.
            </p>
            <Container>
                <Row>
                <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <a href="https://www.youtube.com/watch?v=eEyf00v6I9c" target="_blank"><img src={img3} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img></a>
                </Col>
                <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <a href="https://www.youtube.com/watch?v=pDyNAmJaE48" target="_blank"><img src={img4} style={{maxHeight: "300px", maxWidth: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)", overflow: "hidden"}}></img></a>
                </Col>
                </Row>
            </Container>
            <p></p>
            <h5><b>My Work</b></h5>
            <p>
                As such, I generated a basic action set for an air hockey robot to play, and developed an arbitrary layered strategy system as a testbed for the actions.
                A high level overview of the strategy system can be seen below.
                <br/>
                The code can be found <a href="https://github.com/Jonjjt/Air-Hockey-Project/blob/main/Tang_Jonathan_Version/main.cpp" target="_blank">here</a>, my contribution is from line 377-2502.
            </p>
            <ImageCarousel imageArray={[<img src={img11}/>, <img src={img5}/>, <img src={img6}/>, <img src={img7}/>, <img src={img8}/>, <img src={img9}/>, <img src={img10}/>]} variant="dark"/>
            
        </>
    }
    />
  )
}

export default AirHockeyRobot