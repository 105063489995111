import Carousel from 'react-bootstrap/Carousel';
import Figure1 from "../assets/Animations/0001_2.gif";
import Figure2 from "../assets/Animations/airhockey.gif";
import Figure3 from "../assets/Animations/canelarge.gif";
import "./slider.css"
// import {useNavigate, Link} from 'react-router-dom';

function Slider() {
  return (
    <div className="slider">
      <Carousel fade>
        <Carousel.Item>
          <a href = '/tuntre'>
          <img
            className="d-block w-100"
            src={Figure1}
            alt="First slide"
          />
          </a>
          {/* <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <a href ='/connect-explore'>
          <img
            className="d-block w-100"
            src={Figure3}
            alt="Second slide"
          />
          </a>
          
          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <a href = '/air-hockey-robot'>
          <img
            className="d-block w-100"
            src={Figure2}
            alt="Third slide"
          />
          </a>

          {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Slider;