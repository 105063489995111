import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "./imgcarousel.css";

function ImageCarousel({imageArray, variant}) {
  const MyCarousel = ({images})  => {
    const CarouselItems = [];
    if (images !== undefined){
      for (let i = 0; i < images.length; i++) {
        CarouselItems[i] = 
        <Carousel.Item style={{position: "relative"}}>
          {images[i]}
        </Carousel.Item>
      }
    }
    return (
      <div className="ImageCarousel">
        <Carousel fade variant={variant}>
          {CarouselItems}
        </Carousel>
      </div>
    );
  };
  return (
    <>
      <MyCarousel images={imageArray}/>
    </>
  )
}

export default ImageCarousel;