import React from 'react'
import {Accordion} from 'react-bootstrap';
import './expandablecard.css'

function ExpandableCard({heroimg, bodytext}) {
  return (
    <Accordion defaultActiveKey="0" className="expandableCard">
        <Accordion.Item eventKey="1">
            <Accordion.Header>
                <div>
                    <img src={heroimg}></img>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                {bodytext}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
  )
}

export default ExpandableCard