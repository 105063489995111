import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import "./intro.css"

function Intro({img_src, text}) {
  return (
    <Container>
        <Row>
            <Col sm={6} md={5} lg={3}>
                <img src={img_src} id="profile"></img>
            </Col>
            <Col style={{padding:"15px"}}>
                {text}
            </Col>
        </Row>
    </Container>    
    )
}

export default Intro;