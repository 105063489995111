import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Photos/Projects/Kagu/PNG sequence render/Round 2/0600_5.png";
import SectionTitle from '../components/sectiontitle';
import ImageCarousel from '../components/imgcarousel';
import video from "../assets/Videos/Real_World_Interaction.mp4"
import img1 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_01.png";
import img2 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_02.png";
import img3 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_03.png";
import img4 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_04.png";
import img5 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_05.png";
import img6 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_06.png";
import img7 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_07.png";
import img8 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_08.png";
import img9 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_09.png";
import img10 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_10.png";
import img11 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_11.png";
import img12 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_12.png";
import img13 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_13.png";
import img14 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_14.png";
import img15 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_15.png";
import img16 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_16.png";
import img17 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_17.png";
import img18 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_18.png";
import img19 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_19.png";
import img20 from "../assets/Photos/Projects/Kagu/Final Portfolio Compilation_Page_20.png";

function Kagu() {
  return (
    <ProjectPage 
    title = "Kagu" 
    tags = "System Design, Futurism, VR/AR, Animation"
    hero = {heroImg} 
    content = {
        <>
            <SectionTitle title="Concept Demo Video" />
            <video controls style={{width: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)"}}>
                <source src={video} type="video/mp4" />
            </video>
            <SectionTitle title="Project Overview" />
            <p class="disclaimer">
              *This was a group project done with Adedunke Oyenuga, Saksorn Sespattanachai, and Hazel Gonda. <br />
            </p>
            <p>
              This project was an exercise in futurism - imagining the rise of manufacturing at the edge and "prosumer" society. 
              <br/><br/>
              Kagu is a platform which democratises custom design by way of decentralised manufacturing. 
              Users can create, sell, purchase, and edit their designs from home, and then have them manufactured at local Print Offices. 
              By extension, local production results in a lower carbon footprint. 
              User-created designs will be protected with automated implementations of copyright law through a decentralised ledger to ensure the safety of their intellectual property.
              To best demonstrate the need for a democratised custom design system like Kagu, 
              the industry of custom furniture design was chosen.
            </p>
            <ImageCarousel 
            imageArray={[
            <img src={img1}/>, <img src={img2}/>, <img src={img3}/>, <img src={img4}/>, <img src={img5}/>, <img src={img6}/>, <img src={img7}/>, <img src={img8}/>, <img src={img9}/>, <img src={img10}/>, 
            <img src={img11}/>, <img src={img12}/>, <img src={img13}/>, <img src={img14}/>, <img src={img15}/>, <img src={img16}/>, <img src={img17}/>, <img src={img18}/>, <img src={img19}/>, <img src={img20}/>
            ]} 
            variant="dark"
            />
        </>
    }
    />
  )
}

export default Kagu