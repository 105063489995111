import React from 'react'
import SectionTitle from '../components/sectiontitle';
import {Container, Row, Col, Accordion, Button} from 'react-bootstrap';
import ProjectBlock from '../components/projectblock';
import icon1 from '../assets/Photos/Projects/Tuntre/Tuntre White Name.png';
import projectimage1 from '../assets/Photos/Projects/Tuntre/hero2_denoised.png';
import icon2 from '../assets/Photos/Projects/Connect Explore/Logo white - Copy.png';
import projectimage2 from '../assets/Photos/Projects/Connect Explore/FINALFINALPRESENTATION_Page_37 - Copy.png';
import icon3 from '../assets/Logos/OI/RGB/OI_Master_Icon_White_RGB.png';
import projectimage3 from '../assets/Photos/Projects/Ocean Infinity/untitled5.png';
import projectimage4 from '../assets/Photos/Projects/Vibe/TANG_Jonathan_Page_01.png';
import projectimage5 from '../assets/Photos/Projects/Groot/groot.jpg';
import projectimage6 from '../assets/Photos/Projects/Kagu/PNG sequence render/Round 2/0600_5.png';
import projectimage7 from '../assets/Animations/van and washing machine.gif';
import projectimage8 from '../assets/Photos/Projects/Portal/hero.png';
import projectimage9 from '../assets/Animations/airhockey.gif';

function Projects() {
  return (
    <div>
      <SectionTitle title={"Projects"} />
      <Container>
            <Row>
                <Col>
                <ProjectBlock icon_src={icon1} img_src={projectimage1} href={"/tuntre"} />
                </Col>
                <Col>
                <ProjectBlock icon_src={icon2} img_src={projectimage2} href={"/connect-explore"}/>
                </Col>
                <Col>
                <ProjectBlock project_title={"Remote Signalling System"} img_src={projectimage3} href={"/remote-signal-system"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                <ProjectBlock project_title={"Air Hockey Robot"} img_src={projectimage9} href={"/air-hockey-robot"}/>
                </Col>
                <Col>
                <ProjectBlock project_title={"Vibe"} img_src={projectimage4} href={"/vibe"} />
                </Col>
                <Col>
                <ProjectBlock project_title={"Kagu"} img_src={projectimage6} href={"/kagu"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                <ProjectBlock project_title={"Clay Baby Groot"} img_src={projectimage5} href={"/clay-groot"}/>
                </Col>
                <Col>
                <ProjectBlock project_title={"Green Square Packaging"} img_src={projectimage7} href={"/green-square"}/>
                </Col>
                <Col>
                <ProjectBlock project_title={"Portal"} img_src={projectimage8} href={"/portal"}/>
                </Col>
            </Row>
        </Container>
        <Container style={{opacity: "0.4"}}>
          
        </Container>
    </div>
  )
}

export default Projects