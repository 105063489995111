import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Animations/0001_2.gif";
import SectionTitle from '../components/sectiontitle';
import ImageCarousel from '../components/imgcarousel';
import video from "../assets/Videos/final_1.mp4";
import img1 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_01.png";
import img2 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_02.png";
import img3 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_03.png";
import img4 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_04.png";
import img5 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_05.png";
import img6 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_06.png";
import img7 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_07.png";
import img8 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_08.png";
import img9 from "../assets/Photos/Projects/Green Square/design-2-part-2-2_Page_09.png";

function GreenSquare() {
  return (
    <ProjectPage 
    title = "Green Square Packaging Solutions" 
    tags = "System Design, Animation, Futurism"
    content = {
        <>
            <SectionTitle title="Project Video" />
            <video controls style={{width: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)"}}>
                <source src={video} type="video/mp4" />
            </video>
            <SectionTitle title="Project Overview" />
            <p class="disclaimer">
                *This was a group project in collaboration with Oliver Veal, and Kerim Taşkın.
            </p>
            <p>
                This was a project to explore future circular economy solutions to fast moving consumer goods (FMCG) packaging waste.
            </p>
            <ImageCarousel 
            imageArray={[
            <img src={img1}/>, <img src={img2}/>, <img src={img3}/>, <img src={img4}/>, <img src={img5}/>, <img src={img6}/>, <img src={img7}/>, <img src={img8}/>, <img src={img9}/>
            ]} 
            variant="dark"
            />
        </>
    }/>
  )
}

export default GreenSquare