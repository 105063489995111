import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import "./sidebar.css"

function SideBar() {
  const [show, setShow] = useState(false);

  const sideBarClose = () => setShow(false);
  const sideBarShow = () => setShow(true);

  return (
    <>
      <button type="button" className="d-md-none menubtn" aria-label="Menu" onClick={sideBarShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </button>

      <Offcanvas show={show} onHide={sideBarClose} placement="end" className="ocnav">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Button href="/">Home</Button>
            <Button href="/about">About</Button>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Projects</Accordion.Header>
                    <Accordion.Body>
                        <Button href="/projects">All Projects</Button>
                        <Button href="/tuntre">Tuntre</Button>
                        <Button href="/connect-explore">Connect: Explore</Button>                    
                        <Button href="/remote-signal-system">Remote Signalling System</Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Button href="/cv">CV</Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SideBar;