import React from 'react';
import ProjectPage from '../components/project-page';
import SectionTitle from '../components/sectiontitle';
import ImageCarousel from '../components/imgcarousel';
import video from "../assets/Videos/Portal_Trim.mp4";
import img1 from "../assets/Photos/Projects/Portal/portfolio_Page_1.png";
import img2 from "../assets/Photos/Projects/Portal/portfolio_Page_2.png";
import img3 from "../assets/Photos/Projects/Portal/portfolio_Page_3.png";
import img4 from "../assets/Photos/Projects/Portal/portfolio_Page_4.png";
import img5 from "../assets/Photos/Projects/Portal/portfolio_Page_5.png";

function Portal() {
  return (
    <ProjectPage 
    title = "Portal" 
    tags = "Interaction Design, Mechatronics, Arduino, Programming"
    content = {
        <>
            <SectionTitle title="Project Video" />
            <video controls style={{width: "100%", borderRadius: "15px", boxShadow: "3px 3px 3px rgb(200,200,200)"}}>
                <source src={video} type="video/mp4" />
            </video>
            <SectionTitle title="Project Overview" />
            <p class="disclaimer">
                *This was a group project in collaboration with Brandon Tan.
            </p>
            <p>
                This project was an open ended brief in which we were meant to create a meaningful human interaction.
            </p>
            <ImageCarousel 
            imageArray={[
            <img src={img1}/>, <img src={img2}/>, <img src={img3}/>, <img src={img4}/>, <img src={img5}/>
            ]} 
            variant="dark"
            />
        </>
    }/>
  )
}

export default Portal