import React from 'react';
import ProjectPage from '../components/project-page';
import heroImg from "../assets/Animations/canelarge.gif";
import SectionTitle from '../components/sectiontitle';
import ImageCarousel from '../components/imgcarousel';
import finalImg1 from  "../assets/Photos/Projects/Connect Explore/phase3_29.png";
import finalImg2 from  "../assets/Photos/Projects/Connect Explore/phase3_30.png";
import finalImg3 from  "../assets/Photos/Projects/Connect Explore/phase3_31.png";
import finalImg4 from  "../assets/Photos/Projects/Connect Explore/phase3_33.png";
import finalImg5 from  "../assets/Photos/Projects/Connect Explore/phase3_34.png";
import finalImg6 from  "../assets/Photos/Projects/Connect Explore/phase3_36.png";
import finalImg7 from  "../assets/Photos/Projects/Connect Explore/phase3_37.png";
import rndImg1 from "../assets/Photos/Projects/Connect Explore/rnd_phase2_page_1.png";
import rndImg2 from "../assets/Photos/Projects/Connect Explore/rnd_phase2_page_2.png";
import rndImg3 from "../assets/Photos/Projects/Connect Explore/rnd_phase2_page_3.png";
import rndImg4 from "../assets/Photos/Projects/Connect Explore/rnd_phase2_page_4.png";
import rndImg5 from "../assets/Photos/Projects/Connect Explore/rnd_phase2_page_5.png";
import rndImg6 from "../assets/Photos/Projects/Connect Explore/rnd_phase2_page_6.png";
import rndImg7 from "../assets/Photos/Projects/Connect Explore/rnd_phase2_page_7.png";
import complianceImg1 from "../assets/Photos/Projects/Connect Explore/slide1 v2.png";
import complianceImg2 from "../assets/Photos/Projects/Connect Explore/slide2 v2.png";
import complianceImg3 from "../assets/Photos/Projects/Connect Explore/slide3 v2.png";
import complianceImg4 from "../assets/Photos/Projects/Connect Explore/slide4 v2.png";

function ConnectExplore() {
  return (
    <ProjectPage 
    title = "Connect: Explore" 
    tags = "Design for Undeserved Users, Industrial Design, Product Design, Compliance"
    hero = {heroImg} 
    video = "https://www.youtube.com/embed/BGxrWEJgCog"
    content = {
        <>
            <SectionTitle title="Project Overview" />
            <p class="disclaimer">
              *This was a group project done with Oscar Jones, Anais Engelmann, and Kanya Thavaensan. <br />
              *Renders courtesy of Oscar Jones. <br />
              *Google was not involved with the project in any way, and any use of the Google brand is for academic purposes only. <br />
              *This project was one of fourteen expert picks in the <a href="https://www.londondesignbiennale.com/team-connectexplore" target="_blank">London Design Bienalle</a> with 500 entries. 
            </p>
            <p>
              Explore is a device and system to create an independent museum experience for visually impaired visitors. <br />
            </p>
            <p>
              This project's focal point was designing a hand-held and battery powered tool that not only serves the mass market but also serves the needs of an underserved group. <br />
              We were also required to choose a parent brand that would theoretically be able to expand into our market of interest. We chose Google, and proposed the product line Connect, alongside their existing Nest and Pixel products.
            </p>
            <p>
                Over the course of the 6 month project, I worked on needfinding, competitor market analysis, ideation, interviewed <a href="https://monaminkara.com" target="_blank">Dr. Mona Minkara</a>, and researched opportunity areas in museums and galleries.
            </p>
            <ImageCarousel imageArray={[
            <img src={finalImg7}/>, 
            <img src={finalImg6}/>, 
            <img src={finalImg5}/>, 
            <img src={finalImg4}/>, 
            <img src={finalImg3}/>, 
            <img src={finalImg2}/>, 
            <img src={finalImg1}/>
            ]} variant="dark"/>
            <h4>Concept Development (Unused)</h4>
            <p>
                During the ideation phase of the project, I worked on a conceptual handheld device to aid VI users while grocery shopping. 
                This included prototyping, and early stage systems design. 
                Elements such as being store owned with store built infrastructure were carried over to Explore.
            </p>
            <ImageCarousel imageArray={[
            <img src={rndImg1}/>, 
            <img src={rndImg2}/>, 
            <img src={rndImg3}/>, 
            <img src={rndImg4}/>, 
            <img src={rndImg5}/>, 
            <img src={rndImg6}/>, 
            <img src={rndImg7}/>
            ]} variant="dark"/>

            <h4>Product Compliance Research</h4>
            <p>
                While developing Explore, I was in charge of reading through what was required by EU and UK Legislation (pre-Brexit), and making sure our product complied. <br />
                This involved referencing BSI and ISO standards and standards Google has used in their Declaration of Conformity for other products, and making amendments to our design. <br />
                By extension, I did the content on the packaging renders and the labels on the device.
            </p>
            <ImageCarousel imageArray={[
            <img src={complianceImg1}/>, 
            <img src={complianceImg2}/>, 
            <img src={complianceImg3}/>, 
            <img src={complianceImg4}/>
            ]} variant="dark"/>
        </>
    }
    />
  )
}

export default ConnectExplore